import * as Bs from '@bootstrap-styled/v4'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { PrimaryButton, ButtonText } from '~ui'

const DropdownToggle = styled(Bs.DropdownToggle)`
  background-color: ${({ theme }) => theme.colors.secondaryShade3} !important;
  height: 40px;
  padding: 0 17px 0 10px !important;
  border-radius: 8px !important;
  text-transform: uppercase;
`

const DropdownMenu = styled(Bs.DropdownMenu)`
  min-width: unset !important;
  border-radius: 8px !important;
  border: 2px solid ${({ theme }) => theme.colors.tertiaryShade1} !important;
  background-clip: border-box !important;
  width: -webkit-fill-available;
  padding: 0 !important;
`

const DropdownItem = styled(Bs.DropdownItem)`
  padding: 0 0 0 10px !important;
  height: 40px;
  font-weight: bold !important;
  color: ${({ theme }) => theme.colors.tertiary} !important;
  text-transform: uppercase;
  &:active {
    background: none !important;
  }
`

const LangDropdown = ({
  value,
  onItemClick
}: {
  value: string
  onItemClick: (value: string) => unknown
}) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Bs.Dropdown
      onClick={(e: any) => {
        e.stopPropagation()
      }}
      isOpen={isOpen}
      toggle={() => setIsOpen(!isOpen)}
    >
      <DropdownToggle caret>
        <ButtonText>{value}</ButtonText>
      </DropdownToggle>
      <DropdownMenu>
        {['EN', 'RU', 'ES', 'DE', 'FR', 'TR']
          .filter((l) => l !== value)
          .map((l) => (
            <DropdownItem
              onClick={() => {
                onItemClick(l)
                setIsOpen(false)
              }}
              key={l}
            >
              {l}
            </DropdownItem>
          ))}
      </DropdownMenu>
    </Bs.Dropdown>
  )
}

export const WhitepaperButton = styled(({ className }) => {
  const { t } = useTranslation('account')
  const [lang, setLang] = useState('EN')

  return (
    <PrimaryButton
      onClick={() => {
        window.open(`/LocalTrade-Whitepaper-${lang}.pdf`, '_blank')
      }}
      className={className}
    >
      <ButtonText className="text-center w-100">{t('Whitepaper')}</ButtonText>
      <LangDropdown value={lang} onItemClick={setLang} />
    </PrimaryButton>
  )
})`
  height: 50px;
  padding: 8px 10px !important;
  justify-content: space-between;
`
